export const Images = {
  logo: require("../svg/leadboard-logo.svg").default.src,
  img1: require("../images/img1.jpg").default.src,
  img2: require("../images/img2.jpg").default.src,
  img3: require("../images/img3.jpg").default.src,
  img4: require("../images/img4.jpg").default.src,
  eventsPng: require("../images/eventsPng.png").default.src,
  img5: require("../images/img5.jpg").default.src,
  img6: require("../images/img6.jpg").default.src,
  img7: require("../images/img7.jpg").default.src,
  valid: require("../images/Valid.png").default.src,
  error: require("../images/error.png").default.src,
  search: require("../svg/search.svg").default.src,
  email: require("../svg/email.svg").default.src,
  unsubscribePng: require("../images/unsubscribePng.png").default.src,
  Humility: require("../images/Humility.png").default.src,
};
